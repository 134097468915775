<template>
    <div class="o365-header-cell o365-header-cell-container"
        :draggable="!col.suppressMovable" :data-o365-colindex="col.order"
        :style="{width: col.width+'px', left: col.left+'px'}" :o365-field="col.colId"
        :title="col.headerTitle ?? col.headerName">

        <div v-if="!col.disableResizable" 
            class="o365-sizer" :o365-field="col.colId"
            :class="(gridControl.header.useLeftColumnSizers || col.pinned === 'right') ? 'sizer-left' : 'sizer-right'"></div>
        
        <div class="o365-header-cell-label-container text-truncate">
            <slot v-if="!col.disableMenu" name="menu" :col="col">
                <BaseHeaderCellMenu :col="col"/>
            </slot>
            <slot>
                <component v-if="col.headerLabel" :is="col.headerLabel"></component>
                <div v-else class="o365-header-cell-label" @click="gridControl.header.handleHeaderClick($event, col)">
                    <component v-if="col.slots.headertext" :is="col.slots.headertext" :column="col"></component>
                    <span v-else class="o365-header-cell-text text-truncate">
                        {{col.headerName ?? $t(col.field)}}
                    </span>
                    <span v-if="col.sortable" v-show="col.sort" class="px-1">
                        <i class="bi" :class="{'bi-arrow-up': col.sort === 'asc', 'bi-arrow-down': col.sort === 'desc'}" ></i>
                        <small>{{col.sortOrder}}</small>
                    </span>
                </div>
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';

export interface IBaseHeaderCellProps {
    col: BaseColumn;
};
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { defineAsyncComponent } from 'vue';

const BaseHeaderCellMenu = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseHeaderCellMenu.vue'));

const props = defineProps<IBaseHeaderCellProps>();

const gridControl = useBaseGridControl();

</script>